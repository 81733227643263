import config from "@/config";
import axios from "axios";
import compareVersions from "compare-versions";
import { register } from "register-service-worker";

class ServiceWorker {
  loadManifest() {
    return new Promise((resolve, reject) => {
      axios
        .get("/manifest.json?r=" + Math.random(), {}, {})
        .then((response) => {
          if (response.data["version"]) {
            resolve(response.data);
          } else {
            let error = new Error("version is undefined");
            reject(error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  refreshVersion(reload) {
    console.log("Content is unregistering.");
    if ("serviceWorker" in window.navigator) {
      window.navigator.serviceWorker.ready.then((registration) => {
        registration.unregister().then(() => {
          console.log("Service worker has been unregistered.");
          if (reload === true) {
            this.clearCache(true);
          }
        });
      });

      if (reload === true) {
        setTimeout(() => {
          this.clearCache(true);
        }, 4000);
      }
    } else {
      if (reload === true) {
        this.clearCache(true);
      }
    }
  }

  async clearCache(reloadAfterClear = true) {
    if ("caches" in window) {
      
      await caches.keys().then((names) => {
        console.log(names);
        names.forEach(async (name) => {          
        console.log(name);        
          await caches.delete(name).then(()=>
          {
              console.log("cacheDeleted"+name)
          });
        });
      });
      
      if (reloadAfterClear) {
       console.log("------page must be reload-------");
       window.location.href=""+window.location.href;
       //window.location.href = window.location.href.split('?')[0] + '?update=' + new Date().getTime();
       window.location.reload(true);
      }
    }
  }
  registerServiceWorker() {
    if (process.env.NODE_ENV === "production"||
    process.env.NODE_ENV === "development") {
      register(`${process.env.BASE_URL}serviceWorker.js`, {
        ready() {
          console.log(
            "App is being served from cache by a service worker.\n" +
              "For more details, visit https://goo.gl/AFskqB"
          );
        },
        registered() {
          console.log("Service worker has been registered.");
        },
        cached() {
          console.log("Content has been cached for offline use.");
        },
        updatefound() {
          console.log("New content is downloading.");
        },
        updated() {
          // console.log('New content is available; please refresh.')
        },
        offline() {
          console.log(
            "No internet connection found. App is running in offline mode."
          );
        },
        error(error) {
          console.error("Error during service worker registration:", error);
        },
      });
    }
  }

  initialize() {
    console.log("NODE_ENV: " + process.env.NODE_ENV);
    console.log("BASE_URL: " + process.env.BASE_URL);

    if (
      process.env.NODE_ENV !== "production" &&
      process.env.NODE_ENV !== "development"
    ) {
      console.log("service worker is disabled.");
      return;
    }

    console.log("initialize");

    this.loadManifest()
      .then((data) => {
        let manifestVersion = data.version;
        let storeVersion = localStorage.getItem("__VERSION__");

        let versionString = "";
        versionString += "Local Version: " + config.version;
        //console.log("------local-------"+config.version) ;
        versionString += " / Manifest Version: " + manifestVersion;
        //console.log("------manifest-------"+manifestVersion) ;
        versionString += " @(" + storeVersion + ")";

        if (config.version !== manifestVersion) {
          // if (storeVersion === manifestVersion) {
          //     console.log("Detect new version. try to refresh this page");
          //     return Promise.reject(new Error("need_update"));
          // }
           
          console.log("version diff(version, manifest):", config.version, manifestVersion); 
          console.log("Detect new version. refreshing this page");
          localStorage.setItem("__VERSION__", manifestVersion);
          return ;
        } else {
          localStorage.setItem("__VERSION__", manifestVersion);
        }

        if (!storeVersion) {
          console.log("First Version");
          localStorage.setItem("__VERSION__", manifestVersion);
        }

        this.startVersionCheck({
          interval: 60, // 60secs
        });

        return ;//this.registerServiceWorker();
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  startVersionCheck(options = {}) {
    let { interval } = options;

    if (this._interval) {
      clearInterval(this._interval);
      this._interval = null;
    }

    this._interval = setInterval(() => {
      this.loadManifest().then((data) => {
        let minimumVersion = data.minimumVersion;
        if (compareVersions(config.version, minimumVersion) < 0) {
          this.refreshVersion(true);
        }
      });
    }, 1000 * interval);
  }
  async checkCache(){
    if ("caches" in window) {
      await caches.keys().then((names) => {
        console.log(names);
        names.forEach(async (name) => {          
          console.log(name);        
        });
      });
    }
  }
}

const serviceWorker = new ServiceWorker();
serviceWorker.checkCache();
serviceWorker.initialize();

export default serviceWorker;
